<template>
  <div class="address">
    <div class="address-container">
      <nav-bar
        :isFixed="true"
        :bgc="'background:#fff'"
        :title="'選擇收貨地址'"
      ></nav-bar>
      <div :class="['address-list',{headerTop:!isWeiXin()}]" v-if="address.length > 0">
        <div class="address-item" v-for="item in address" :key="item.id">
          <div
            class="address-check"
            @click="handleCheck(item)"
            v-if="['1','2'].includes($route.query.type)"
          >
            <img
              v-if="item.id === checkId"
              src="@/assets/icons/checked.png"
              alt=""
            />
            <img v-else src="@/assets/icons/checkround.png" alt="" />
          </div>
          <div class="address-info">
            <div class="user-info">
              <div class="user-name">{{ item.name }}</div>
              <div class="user-phone">{{ item.phone }}</div>
            </div>
            <div class="area">{{ item.address }}</div>
            <div class="city">
              {{ spliceAddress(item.province) }}
              {{ spliceAddress(item.city) }}
              {{ spliceAddress(item.district) }}
              {{ item.street }}
            </div>
            <div class="default-check">
              <div class="check-left" @click="handleEditDefaultAddress(item)">
                <img
                  v-if="item.isDefault"
                  src="@/assets/icons/defaultcheck.png"
                  alt=""
                />
                <img v-else src="@/assets/icons/default.png" alt="" />
                <div class="default-text">{{ $t("默認地址") }}</div>
              </div>
              <div class="Controls">
                <div @click="getEditaddress(item)">
                  <img src="@/assets/icons/editaddress.png" alt="" />
                  {{ $t("修改") }}
                </div>
                <div @click="handleDelAddress(item)">
                  <img src="@/assets/icons/deladdress.png" alt="" />
                  {{ $t("刪除") }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="addressempty" v-else>
        <img src="@/assets/image/empty-address.png" alt="" />
        <div>{{ $t("暂无收貨地址") }}</div>
      </div>
      <div class="address-footer" @click="handleAddAddress">
        <div class="address-btn">{{ $t("新增收貨地址") }}</div>
      </div>
      <van-dialog v-model:show="isDel" :showConfirmButton="false">
        <div class="register-dialog">
          <div class="register-text">
            <div>{{ $t("確認刪除？") }}</div>
          </div>
          <div class="register-btn">
            <div class="Cancel" @click="isDel = false">{{ $t("取消") }}</div>
            <div class="Confirm" @click="getverify">{{ $t("確認") }}</div>
          </div>
        </div>
      </van-dialog>
    </div>
  </div>
</template>

<script>
import { getEditDefaultAddress, getDelShippingAddress } from "@/api/user";
import { mapState } from "vuex";
import mixins from "@/utils/mixins.js";
export default {
  mixins: [mixins],

  data() {
    return {
      addressList: [],
      isDel: false,
      id: ""
    };
  },
  computed: {
    ...mapState("user", ["checkId", "address"])
  },
  async created() {
    await this.$store.dispatch("user/getAddressList", this.$route.query.id);
  },
  methods: {
    async getEditaddress(item) {
      this.$router.push("/user/addressEdit?edit=true");
      window.localStorage.setItem("addressEditList", JSON.stringify(item));
    },
    async getverify() {
      await getDelShippingAddress({ addressId: this.id });
      this.$store.dispatch("user/getAddressList");
    },
    async handleDelAddress(item) {
      this.isDel = true;
      this.id = item.id;
    },
    handleAddAddress() {
      this.$router.push("/user/addressEdit");
    },
    async handleEditDefaultAddress(item) {
      const res = await getEditDefaultAddress({ addressId: item.id });
      if (res.resultID === 1200) {
        this.$store.dispatch("user/getAddressList");
      }
    },
    spliceAddress(str) {
      const regex = new RegExp(`(?:${"●"})(.*)`);
      const match = (str || "").match(regex);
      return match && match[1] ? match[1] + "," : "";
    },
    handleCheck(item) {
      this.$store.dispatch("user/getEditAddress", item.id);
      if(this.$route.query.type === '2'){
        this.$router.go(-1);
      }else{
        this.$router.push("/order/confirm2");
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.address-list {
  padding: 16px 10px;
  padding-bottom: 60px;
  margin-top: 16px;
  .address-item {
    padding: 20px 16px;
    background-color: #fff;
    border-radius: 12px;
    display: flex;
    align-items: center;
    margin-bottom: 12px;
    .address-check {
      width: 24px;
      margin-right: 16px;
    }
    .address-info {
      flex: 1;
    }
    .user-info {
      display: flex;
      justify-content: space-between;
      font-weight: 500;
      font-size: 17px;
      color: #1a1a1a;
      margin-bottom: 8px;
    }
    .area {
      font-weight: 500;
      font-size: 16px;
      color: #1a1a1a;
      margin-bottom: 8px;
    }
    .city {
      font-weight: 500;
      font-size: 13px;
      color: #666666;
      padding-bottom: 8px;
      border-bottom: 1px solid #f8f8f8;
    }
    .default-check {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 8px;
      .check-left {
        display: flex;
        align-items: center;
        font-size: 13px;
        color: #1a1a1a;
        .default-text {
          margin-left: 6px;
        }
      }
      img {
        width: 16px;
        height: 16px;
      }
      .Controls {
        display: flex;
        div {
          display: flex;
          align-items: center;
          margin-left: 12px;
          font-family: PingFang SC, PingFang SC;
          font-weight: 400;
          font-size: 13px;
          color: #666666;
        }
      }
    }
  }
}
.address-footer {
  background: #fff;
  padding: 9px 16px;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  .address-btn {
    background: #90d12e;
    border-radius: 8px 8px 8px 8px;
    padding: 10px;
    text-align: center;
    color: #fff;
  }
}
.addressempty {
  text-align: center;
  img {
    width: 120px;
    margin: auto;
    margin-top: 200px;
  }
}
.register-dialog {
  padding: 24px;
}
.register-text {
  font-family: PingFang SC, PingFang SC;
  font-weight: 400;
  font-size: 18px;
  color: #1a1a1a;
  text-align: center;
  span {
    color: #3491fa;
  }
}
.register-btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .Cancel,
  .Confirm {
    flex: 1;
    text-align: center;
    margin-top: 32px;
  }
  .Cancel {
    background: #eeeeee;
    border-radius: 12px 12px 12px 12px;
    padding: 8px;
    margin-right: 12px;
  }
  .Confirm {
    font-size: 16px;
    color: #ffffff;
    background-color: #90d12e;
    border-radius: 12px 12px 12px 12px;
    padding: 8px;
  }
}
</style>
